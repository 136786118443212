body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F7F7F7;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-moz-selection { /* Code for Firefox */
  color: #EEF0F2;
  background: #0D919F;
}

::selection {
  color: #EEF0F2;
  background: #0D919F;
}
*::-webkit-scrollbar {
  height: 10px;
  width: 5px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #EEF0F2;
}

*::-webkit-scrollbar-track:hover {
  background-color: #EEF0F2;
}

*::-webkit-scrollbar-track:active {
  background-color: #EEF0F2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #8AC219;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #8AC219;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #8AC219;
}